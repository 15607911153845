import Vue from "vue";
import { sipUser, sipLogout } from "@/api";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import router from "vue-router";
const user = {
  state: {
    token: "",
    name: "",
    avatar: "",
    roles: [],
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
  },

  actions: {
    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        sipUser()
          .then((response) => {
            const data = response.data;
            if (response.success && data.id) {
              commit("SET_ROLES", data.roles);
              commit("SET_INFO", data);
              resolve(data);
            } else {
              throw response;
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        Vue.ls.remove(ACCESS_TOKEN);

        sipLogout(state.token)
          .then(() => {
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
  },
};

export default user;
