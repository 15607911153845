import { axios } from "@/utils/request2";

//常用工单
// 查询
export function commList(params) {
  return axios({ url: "/comm/list", method: "get", params: params });
}
//新增
export function commInsert(data) {
  return axios({ url: "/comm/insert", method: "post", data: data });
}
//修改
export function commUpdate(data) {
  return axios({ url: "/comm/update", method: "post", data: data });
}
//删除
export function commRemove(id) {
  return axios({ url: "/self/comm/delete", method: "post", data: { id } });
}
//工单
// 类型
export function typeList(params) {
  return axios({ url: "type/list", method: "get", params: params });
}
//新增
export function problemInsert(data) {
  return axios({ url: "/problem/insert ", method: "post", data: data });
}
//查询--用户
export function problemListUser(params) {
  return axios({ url: "/problem/list/user", method: "get", params: params });
}
// 查询--管理员
export function problemListAdmin(params) {
  return axios({ url: "/problem/list/admin", method: "get", params: params });
}
//修改

export function problemUpdateUser(data) {
  return axios({ url: "/problem/update/user", method: "post", data: data });
}
export function problemUpdateAdmin(data) {
  return axios({ url: "/problem/update/admin", method: "post", data: data });
}
//关闭

export function problemClose(data) {
  return axios({ url: "/problem/update/close", method: "post", data: data });
}

//notice
export function noticeListGet(params) {
  return axios({ url: "/notice/list", method: "get", params: params });
}
export function noticeInsert(data) {
  return axios({ url: "/notice/insert", method: "post", data: data });
}

export function noticeDelete(id) {
  return axios({ url: "/notice/delete", method: "delete", data: [id] });
}
