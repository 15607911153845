import Vue from 'vue';
import router from './router';
import store from './store';

import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import notification from 'ant-design-vue/es/notification';
import { setDocumentTitle } from '@/utils/domUtil';
import {ACCESS_TOKEN, CLIENT_LOGIN} from '@/store/mutation-types';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  'login',
  'register',
  'rePwd',
  'index',
  '404',
  'taobao',
  'alibaba',
  'join',
  'systemNotice',
  'pdd',
  'douyin',
  'clientLogin' // client
]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  // 设置默认token在这里修改即可
  // Vue.ls.set(ACCESS_TOKEN, '31119df5a8718b7e35003879e88e223177f8e01fc00970e71cf6d0519aa1c24c03b130dd7edc1d8a5b03b6053edb212d50fc851afe12698918f1c1561d77675c', 7 * 24 * 60 * 60 * 1000)
  NProgress.start(); // start progress bar
  if (location.href.indexOf('clientToken') > -1) {
    Vue.ls.set(ACCESS_TOKEN, location.href.split('clientToken=')[1].split('&')[0], 7 * 24 * 60 * 60 * 1000)
    window.location.href = location.href.split('?')[0]
  }
  // 设置标题
  if (window && window.client) {
    // 客户端标题 礼品代发
    const clientLogin = Vue.ls.get(CLIENT_LOGIN)
    if (clientLogin && clientLogin.taobao && clientLogin.taobao.name && clientLogin.taobao.id) {
      // 使用客户端持久化的信息，修改标题
      setDocumentTitle(`礼品代发-已登录淘宝店铺（${clientLogin.taobao.name}）`)
    } else {
      setDocumentTitle(`礼品代发`);
    }
    // icon 创建icon标签 客户端logo 礼品代发
    // const link = document.createElement('link');
    // link.type = 'image/x-icon';
    // link.rel = 'shortcut icon';
    // link.href = './logo.png';
    // document.getElementsByTagName('head')[0].appendChild(link);
  } else {
    // 分站用logo当标题
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${baseData.siteTitle}`);
  }

  // eslint-disable-next-line no-undef
  if (Vue.ls.get(ACCESS_TOKEN)) {
    if (to.path === '/index') {
      // next({ path: "/user" });
      next();
      NProgress.done();
    } else {
      /* has token */
      if (store.getters.roles.length === 0) {
        store
          .dispatch('GetInfo')
          .then((res) => {
            const roles = res && res.roles;
            roles.permissionList = roles;
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters);
              const redirect = decodeURIComponent(
                from.query.redirect || to.path
              );
              if (to.path === redirect) {
                // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true });
              } else {
                // 跳转到目的路由
                next({ path: redirect });
              }
              NProgress.done();
            });
          })
          .catch((error) => {
            notification.warn({
              message: '登录失效',
              description: error.msg
            });
            store.dispatch('Logout').then((res) => {
              location.reload();
            });
          });
      } else {
        next();
      }
    }
  } else {
    console.info('to.name', to.name);
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } });
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 *    <a v-action:edit @click="edit(record)">修改</a>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *  - 当后台权限跟 pro 提供的模式不同时，只需要针对这里的权限过滤进行修改即可
 *
 *  @see https://github.com/sendya/ant-design-pro-vue/pull/53
 */
const action = Vue.directive('action', {
  bind: function(el, binding, vnode) {
    const actionName = binding.arg;
    const roles = store.getters.roles;
    const elVal = vnode.context.$route.meta.permission;
    const permissionId = (elVal instanceof String && [elVal]) || elVal;
    roles.permissions.forEach((p) => {
      if (!permissionId.includes(p.permissionId)) {
        return;
      }
      if (p.actionList && !p.actionList.includes(actionName)) {
        (el.parentNode && el.parentNode.removeChild(el)) ||
          (el.style.display = 'none');
      }
    });
  }
});

export { action };
