<template>
  <div class="logo" @click="()=>{$router.push('/index')}">
    <img :src="baseData.logo" alt v-show="baseData.logo" />
    <h1 v-if="showTitle && screenWidth >800">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Logo",
  components: {},
  props: {
    title: {
      type: String,
      default: baseData.systemTitle, // 左上角显示的名称
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      baseData: baseData,
      screenWidth: "",
    };
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>
<style lang="less" scoped>
.logo {
  cursor: pointer;
  margin-left: 32px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
  h1 {
    margin: 0;
    font-size: 21px;

    color: #fff;
    padding-left: 7px;
  }
}
</style>