<template>
  <div class="footer">
    <div class="copyright">
      Copyright © 2020 {{ baseData.bottom }} 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank">{{
        baseData.beian
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {
      baseData: baseData
    };
  },
  created() {}
};
</script>

<style lang="less" scoped>
.footer {
  padding: 0 24px 24px;

  margin: 48px 0 0;
  text-align: center;

  .links {
    margin-bottom: 8px;

    a {
      color: rgba(0, 0, 0, 0.45);

      &:hover {
        color: rgba(0, 0, 0, 0.65);
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>
