import Menu from "ant-design-vue/es/menu";
import Icon from "ant-design-vue/es/icon";

const { Item, SubMenu } = Menu;

export default {
  name: "SMenu",
  props: {
    menu: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: "dark",
    },
    mode: {
      type: String,
      required: false,
      default: "inline",
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      openKeys: [],
      selectedKeys: [],
      cachedOpenKeys: [],
    };
  },
  computed: {
    //所有的栏目
    rootSubmenuKeys: (vm) => {
      const keys = [];
      vm.menu.forEach((item) => keys.push(item.path));
      return keys;
    },
  },
  created() {
    this.updateMenu();
  },
  watch: {
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys.concat();
        this.openKeys = [];
      } else {
        this.openKeys = this.cachedOpenKeys;
      }
    },
    $route: function() {
      this.updateMenu();
    },
  },
  methods: {
    // select menu item
    onOpenChange(openKeys) {
      console.log(openKeys);
      // 在水平模式下时执行，并且不再执行后续
      if (this.mode === "horizontal") {
        this.openKeys = openKeys;
        return;
      }
      // 非水平模式时
      // const latestOpenKey = openKeys.find(
      //   (key) => !this.openKeys.includes(key)
      // );

      // --------
      // if (!this.rootSubmenuKeys.includes(latestOpenKey)) {
      //   this.openKeys = openKeys;
      // } else {
      //   this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      // }
    },
    updateMenu() {
      const routes = this.$route.matched.concat();

      if (routes.length >= 4 && this.$route.meta.hidden) {
        routes.pop();
        this.selectedKeys = [routes[2].path];
      } else {
        this.selectedKeys = [routes.pop().path];
      }

      const openKeys = [];
      if (this.mode === "inline") {
        routes.forEach((item) => {
          openKeys.push(item.path);
        });
      }

      this.collapsed
        ? (this.cachedOpenKeys = openKeys)
        : (this.openKeys = openKeys);
    },

    // render
    renderItem(menu) {
      if (!menu.hidden) {
        return menu.children && !menu.hideChildrenInMenu
          ? this.renderSubMenu(menu)
          : this.renderMenuItem(menu);
      }
      return null;
    },
    renderMenuItem(menu) {
      const target = menu.meta.target || null;
      const tag = (target && "a") || "router-link";
      const props = { to: { name: menu.name } };
      const attrs = { href: menu.path, target: menu.meta.target };
      return (
        <Item {...{ key: menu.path }}>
          <tag {...{ props, attrs }}>
            {/* {this.renderIcon(menu.meta.icon)} */}
            <span style="fontWeight:600">{menu.meta.title}</span>
          </tag>
        </Item>
      );
    },
    renderSubMenu(menu) {
      const itemArr = [];
      if (!menu.hideChildrenInMenu) {
        menu.children.forEach((item) => itemArr.push(this.renderItem(item)));
      }
      return (
        <SubMenu {...{ key: menu.path }}>
          <span slot="title">
            {this.renderIcon(menu.meta.icon)}
            <span>{menu.meta.title}</span>
          </span>
          {itemArr}
        </SubMenu>
      );
    },
    renderIcon(icon) {
      if (icon === "none" || icon === undefined) {
        return null;
      }
      const props = {};
      typeof icon === "object" ? (props.component = icon) : (props.type = icon);
      return <Icon {...{ props }} />;
    },
  },
  render() {
    const { mode, theme, menu } = this;
    const props = {
      mode: mode,
      //统一使用亮色主题
      theme: "light",
      // openKeys: this.openKeys,
      defaultOpenKeys: this.rootSubmenuKeys,
    };
    const on = {
      select: (obj) => {
        this.selectedKeys = obj.selectedKeys;
        this.$emit("select", obj);
      },
      openChange: this.onOpenChange,
    };

    const menuTree = menu.map((item) => {
      if (item.hidden) {
        return null;
      }
      return this.renderItem(item);
    });
    return (
      <Menu vModel={this.selectedKeys} {...{ props, on: on }}>
        {menuTree}
      </Menu>
    );
  },
};
