<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { AppDeviceEnquire } from "@/utils/mixin";
import { clientNowInsert, clientInsert, ipGet } from "@api"; // 记录客户端的打开时长

export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      clientNowId: 0,
      clientInfo: { id: 0, ip: "", mac: "", version: "" }, // TODO 机器码 ip，客户端才会有
      locale: zhCN,
    };
  },
  created() {
    this.initClient();
  },
  mounted() {
    if (window.client) {
      const { ipcRenderer } = window.electron;
      // 更新软件
      ipcRenderer.on("open-update", () => {
        localStorage.setItem("open-update", "1");
      });
      // 检查更新返回
      ipcRenderer.on("check-update", (_, args) => {
        if (!args) {
          this.$notification["info"]({
            message: "检查更新",
            description: "未发现软件更新",
            duration: 3,
          });
        }
      });
    }
  },
  methods: {
    async initClient() {
      try {
        this.clientInfo.mac = window.machine;
        console.info("window.machine", window.machine);
        this.clientInfo.version = window.client;

        try {
          // const ipResult = await ipGet();
          // 返回包括 内网和公网 IP
          const getResult = await this.getNetworkIP();

          const ipResult =
            getResult.length === 1
              ? getResult[0]
              : getResult.find(item => !item.includes("192"));

          this.clientInfo.ip = ipResult;
          // this.clientInfo.ip = ipResult
          //   .split("您的IP地址是：")[1]
          //   .split("<")[0];
          console.info("[this.clientInfo.ip]:", this.clientInfo.ip);
        } catch (e) {
          this.clientInfo.ip = "0.0.0.0";
        }
        // clientInsert(this.clientInfo);
        // 定時任務 1分鐘刷新一次客戶端的在綫情況
        // this.clientNowTime();
        // 获取回调信息
        // window.chrome.webview.addEventListener('message', event => {
        //   console.info('获取到-event', event)
        // });
      } catch (e) {
        console.info("初始化event失败", e);
      }
    },
    // 定时刷新登录记录
    clientNowTime() {
      clientNowInsert(this.clientInfo).then(result => {
        console.log("result", result);
        if (this.clientInfo.id === 0) {
          // 設置id為返回的id
          this.clientInfo.id = result.data;
        }
        // 定時任務 1分鐘刷新一次客戶端的在綫情況
        setTimeout(() => {
          this.clientNowTime();
        }, 1000 * 60);
      });
    },
    // 获取网络 IP
    getNetworkIP: () => {
      const RTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;
      const useWebKit = !!window.webkitRTCPeerConnection;

      const mediaConstraints = {
        optional: [{ RtpDataChannels: true }],
      };

      // 需要的ICEServer
      const ICEServers = {
        iceServers: [
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      };
      const pc = new RTCPeerConnection(ICEServers, mediaConstraints);
      // 网络协商的过程
      pc.onicecandidate = ice => {
        if (ice.candidate) {
          handleCandidate(ice.candidate.candidate);
        }
      };
      pc.createDataChannel("");
      //创建一个SDP(session description protocol)会话描述协议 是一个纯文本信息 包含了媒体和网络协商的信息
      pc.createOffer(
        result => {
          pc.setLocalDescription(
            result,
            () => {},
            () => {}
          );
        },
        () => {}
      );
      // ip 处理
      const handleCandidate = candidate => {
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
        const hasIp = ipRegex.exec(candidate);
        if (hasIp) return ipRegex.exec(candidate)[1];
      };
      return new Promise(resolve => {
        setTimeout(() => {
          const lines = pc.localDescription.sdp.split("\n");
          const filterRes = lines.filter(item => item.includes("udp"));
          const resultList = filterRes
            .map(line => {
              if (line.includes("a=candidate:")) return handleCandidate(line);
            })
            .filter(item => item !== undefined);
          resolve(resultList);
        }, 1000);
      });
    },
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
