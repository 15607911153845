// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView } from "@/layouts";

export const asyncRouterMap = [
  {
    path: "/",
    name: "user",
    component: BasicLayout,
    meta: { title: "礼品代发" },
    redirect: "/user",
    children: [
      {
        path: "/user",
        name: "order",
        redirect: "/goods/purchase",
        component: RouteView,
        meta: {
          title: "订单管理",
          keepAlive: true,
          icon: "ordered-list",
          permission: ["NORMAL"]
        },
        children: [
          {
            path: "/goods/purchase",
            name: "purchase",
            component: () => import("@/views/goods/purchase.vue"),
            meta: {
              hiddenHeaderContent: false,
              title: "礼品采购",
              icon: "gift",
              permission: ["NORMAL"]
            }
          },
          // {
          //   path: '/goods/purchaseT',
          //   name: 'purchaseT',
          //   component: () => import('@/views/goods/purchase-two.vue'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '无货源下单',
          //     icon: 'gift',
          //     permission: ['NORMAL']
          //   }
          // },
          {
            path: "/order/normal",
            name: "orderNormal",
            component: () => import("@/views/order/normal"),
            meta: {
              hiddenHeaderContent: false,
              title: "订单列表",
              icon: "shopping-cart",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/order/normal/detail",
            name: "orderNormalDetail",
            component: () => import("@/views/orderDetail/normal"),
            meta: {
              hiddenHeaderContent: false,
              title: "发货列表",
              icon: "shopping-cart",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/personal/storeBind",
            name: "storeBind",
            component: () => import("@/views/account/storeBind"),
            meta: {
              title: "店铺绑定",
              hidden: true,
              icon: "appstore",
              permission: ["NORMAL"]
            }
          },
          // {
          //   path: "/client/send",
          //   name: "clientSend",
          //   component: () => import("@/views/client/send"),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: "本地发货",
          //     icon: "shopping-cart",
          //     permission: ["NORMAL"]
          //   }
          // }
          // {
          //   path: '/order/expSend',
          //   name: 'expSend',
          //   component: () => import('@/views/expSend'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '插件发货',
          //     icon: 'shopping-cart',
          //     permission: ['NORMAL']
          //   }
          // }
        ]
      },

      {
        path: "/personal",
        name: "personal",
        component: RouteView,
        meta: { title: "个人中心", icon: "user", permission: ["NORMAL"] },
        children: [
          {
            path: "/account/base",
            name: "accountBase",
            component: () => import("@/views/account/base"),
            meta: {
              title: "基础信息",
              hidden: true,
              icon: "info",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/account/balance",
            name: "accountBalance",
            component: () => import("@/views/account/balance"),
            meta: {
              title: "余额管理",
              hidden: true,
              icon: "money-collect",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/account/order",
            name: "account-order",
            component: () => import("@/views/order/inviter"),
            meta: {
              title: "邀请奖励",
              hidden: true,
              icon: "info",
              permission: ["NORMAL"],
              hostLimit: "dtt.lipw.com"
            }
          },
          {
            path: "/account/extension",
            name: "account-extension",
            component: () => import("@/views/account/extension"),
            meta: {
              title: "邀请好友",
              hidden: true,
              icon: "key",
              permission: ["NORMAL"],
              hostLimit: "dtt.lipw.com"
            }
          }
        ]
      },
      {
        path: "/kefu",
        name: "kefu",
        component: RouteView,
        meta: {
          title: "后台管理",
          icon: "hdd",
          permission: ["CUSTOMER", "ADMIN"]
        },
        children: [
          {
            path: "/kefu/repair",
            name: "kefuRepair",
            component: () => import("@/views/kefu/repair"),
            meta: {
              title: "工单列表",
              hidden: true,
              icon: "info",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/kefu/order",
            name: "kefuOrder",
            component: () => import("@/views/kefu/order"),
            meta: {
              title: "订单查询",
              hidden: true,
              icon: "info",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/kefu/user",
            name: "kefuUser",
            component: () => import("@/views/kefu/user"),
            meta: {
              title: "用户查询",
              hidden: true,
              icon: "info",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/kefu/user2",
            name: "kefuUser2",
            component: () => import("@/views/kefu/user2"),
            meta: {
              hiddenHeaderContent: false,
              title: "用户查询2",
              icon: "user",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/storeManager",
            name: "storeManager",
            component: () => import("@/views/kefu/storeManager"),
            meta: {
              hiddenHeaderContent: false,
              title: "仓库管理",
              icon: "tool",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/order/forwarderAll",
            name: "orderForwarderAll",
            component: () => import("@/views/order/forwarderAll"),
            meta: {
              hiddenHeaderContent: false,
              title: "所有出售订单",
              icon: "user",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/kefu/oem",
            name: "kefuOem",
            component: () => import("@/views/kefu/oem"),
            meta: {
              title: "贴牌管理",
              hidden: true,
              icon: "info",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/kefu/comm",
            name: "kefuComm",
            component: () => import("@/views/kefu/comm"),
            meta: {
              title: "常见工单",
              hidden: true,
              icon: "info",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/notice/notice",
            name: "adminNotice",
            component: () => import("@/views/notice/notice"),
            meta: {
              hiddenHeaderContent: false,
              title: "公告管理",
              icon: "tool",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/close/close",
            name: "adminClose",
            component: () => import("@/views/close/close"),
            meta: {
              hiddenHeaderContent: false,
              title: "自动关闭管理",
              icon: "tool",
              permission: ["CUSTOMER", "ADMIN"]
            }
          },
          {
            path: "/complaint",
            name: "adminComplaint",
            component: () => import("@/views/complaint/index"),
            meta: {
              hiddenHeaderContent: false,
              title: "投诉查询",
              icon: "tool",
              permission: ["CUSTOMER", "ADMIN"]
            }
          }
        ]
      },
      {
        path: "/visit",
        name: "visit",
        component: RouteView,
        meta: {
          title: "回访管理",
          icon: "phone",
          permission: ["CUSTOMER"]
        },
        children: [
          {
            path: "/visit/clientList",
            name: "clientList",
            component: () => import("@/views/kefu/clientList"),
            meta: {
              title: "客户列表",
              hidden: true,
              permission: ["CUSTOMER"]
            }
          },
          {
            path: "/visit/statistical",
            name: "statistical",
            component: () => import("@/views/kefu/statistical"),
            meta: {
              title: "数据统计",
              hidden: true,
              permission: ["CUSTOMER"]
            }
          }
        ]
      },
      {
        path: "/forwarder",
        name: "forwarder",
        component: RouteView,
        meta: { title: "代发管理", icon: "global", permission: ["FORWARDER"] },
        children: [
          {
            path: "/goods/list",
            name: "goodsList",
            component: () => import("@/views/goods/list"),
            meta: {
              hiddenHeaderContent: false,
              title: "商品管理",
              icon: "shopping",
              permission: ["FORWARDER"]
            }
          },
          {
            path: "/order/forwarder",
            name: "orderForwarder",
            component: () => import("@/views/order/forwarder"),
            meta: {
              hiddenHeaderContent: false,
              title: "已出售订单",
              icon: "file-text",
              permission: ["FORWARDER"]
            }
          },
          {
            path: "/order/sell",
            name: "orderSell",
            component: () => import("@/views/orderDetail/sell"),
            meta: {
              hiddenHeaderContent: false,
              title: "发货查询",
              icon: "file-text",
              permission: ["FORWARDER"]
            }
          },
          {
            path: "/address/premium",
            name: "address-premium",
            component: () => import("@/views/address/premium"),
            meta: {
              hiddenHeaderContent: false,
              title: "溢价设置",
              icon: "strikethrough",
              permission: ["FORWARDER"]
            }
          },
          {
            path: "/address/index",
            name: "addressIndex",
            component: () => import("@/views/address/index"),
            redirect: "/address/base",
            hideChildrenInMenu: true,
            meta: {
              title: "快递设置",
              icon: "setting",
              permission: ["FORWARDER"]
            },
            children: [
              {
                path: "/address/base",
                name: "addressBase",
                component: () => import("@/views/address/base"),
                meta: {
                  title: "基础信息",
                  hidden: true,
                  permission: ["FORWARDER"]
                }
              },
              {
                path: "/address/logistics",
                name: "addressLogistics",
                component: () => import("@/views/address/logistics"),
                meta: {
                  title: "快递公司",
                  hidden: true,
                  permission: ["FORWARDER"]
                }
              }
            ]
          }
        ]
      },
      {
        path: "/admin",
        name: "admin",
        component: RouteView,
        meta: {
          title: "系统管理员",
          icon: "laptop",
          permission: ["ADMIN"]
        },
        children: [
          {
            path: "/admin/user",
            name: "adminUser",
            component: () => import("@/views/admin/user"),
            meta: {
              hiddenHeaderContent: false,
              title: "用户管理",
              icon: "user",
              permission: ["ADMIN"]
            }
          },
          {
            path: "/admin/oem",
            name: "adminOem",
            component: () => import("@/views/admin/oem"),
            meta: {
              hiddenHeaderContent: false,
              title: "贴牌管理",
              icon: "tag",
              permission: ["ADMIN"]
            }
          },
          {
            path: "/admin/assignUser",
            name: "assignUser",
            component: () => import("@/views/admin/assignUser"),
            meta: {
              hiddenHeaderContent: false,
              title: "用户分配",
              icon: "tag",
              permission: ["ADMIN"]
            }
          },

          // {
          //   path: '/admin/analysis',
          //   name: 'adminAnalysis',
          //   component: () => import('@/views/admin/analysis'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '平台分析',
          //     icon: 'tag',
          //     permission: ['ADMIN', 'FINANCE']
          //   }
          // },
          {
            path: "/admin/log",
            name: "adminLog",
            component: () => import("@/views/admin/log"),
            meta: {
              hiddenHeaderContent: false,
              title: "操作日志",
              icon: "tag",
              permission: ["ADMIN"]
            }
          },
          // {
          //   path: '/admin/oemList',
          //   name: 'adminoemList',
          //   component: () => import('@/views/admin/oemList'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '分站列表',
          //     icon: 'tag',
          //     permission: ['ADMIN']
          //   }
          // },

          {
            path: "/admin/serve",
            name: "adminServe",
            component: () => import("@/views/admin/serve"),
            meta: {
              hiddenHeaderContent: false,
              title: "接口调用记录",
              icon: "tag",
              permission: ["ADMIN"]
            }
          },
          {
            path: "/admin/partition",
            name: "adminPartition",
            component: () => import("@/views/admin/partition"),
            meta: {
              hiddenHeaderContent: false,
              title: "商品分区设置",
              icon: "tool",
              permission: ["ADMIN"]
            }
          },
          {
            path: "/admin/ads",
            name: "adminAds",
            component: () => import("@/views/admin/ads"),
            meta: {
              hiddenHeaderContent: false,
              title: "广告图管理",
              icon: "tool",
              permission: ["ADMIN"]
            }
          }
        ]
      },

      {
        path: "/financeAdmin",
        name: "financeAdmin",
        redirect: "",
        component: RouteView,
        meta: {
          title: "财务管理员",
          icon: "pay-circle",
          permission: ["ADMIN", "FINANCE"]
        },
        children: [
          {
            path: "/admin/cash",
            name: "adminCash",
            component: () => import("@/views/admin/cash"),
            meta: {
              hiddenHeaderContent: false,
              title: "提现审核",
              icon: "audit",
              permission: ["ADMIN", "FINANCE"]
            }
          },
          {
            path: "/admin/record",
            name: "adminRecord",
            component: () => import("@/views/admin/record"),
            meta: {
              hiddenHeaderContent: false,
              title: "提现记录",
              icon: "audit",
              permission: ["ADMIN", "FINANCE"]
            }
          },
          {
            path: "/admin/bill",
            name: "admin-bill",
            component: () => import("@/views/admin/bill"),
            meta: {
              hiddenHeaderContent: false,
              title: "财务统计",
              icon: "file-text",
              permission: ["ADMIN", "FINANCE"]
            }
          },
          {
            path: "/admin/order",
            name: "admin-order",
            component: () => import("@/views/order/admin"),
            meta: {
              hiddenHeaderContent: false,
              title: "订单统计",
              icon: "file-text",
              permission: ["ADMIN", "FINANCE"]
            }
          }
          // {
          //   path: '/admin/profit',
          //   name: 'admin-profit',
          //   component: () => import('@/views/admin/profit'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '分站收入',
          //     icon: 'file-text',
          //     permission: ['ADMIN', 'FINANCE']
          //   }
          // }
        ]
      },
      {
        path: "/repair",
        name: "repair",
        redirect: "",
        component: RouteView,
        meta: {
          title: "工单中心",
          icon: "exception",
          permission: ["ADMIN", "NORMAL"]
        },
        children: [
          {
            path: "/repair/oderSumbit",
            name: "oderSumbit",
            component: () => import("@/views/repair/oderSumbit"),
            meta: {
              hiddenHeaderContent: false,
              title: "提交工单",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/repair/myOrder",
            name: "repairmyOrder",
            component: () => import("@/views/repair/myOrder"),
            meta: {
              hiddenHeaderContent: false,
              title: "我的工单",
              permission: ["NORMAL"]
            }
          }
        ]
      },
      {
        path: "/oem",
        name: "oem",
        redirect: "",
        component: RouteView,
        meta: { title: "网站后台", icon: "tag", permission: ["OEM", "NORMAL"] },
        children: [
          {
            path: "/oem/manage",
            name: "oem-manage",
            component: () => import("@/views/oem/manage"),
            meta: {
              hiddenHeaderContent: false,
              title: "网站管理",
              icon: "file-text",
              permission: ["OEM", "NORMAL"],
              hostLimit: "dtt.lipw.com"
            }
          },
          {
            path: "/oem/child",
            name: "oemChild",
            component: () => import("@/views/oem/child"),
            meta: {
              title: "下级分站",
              hidden: true,
              icon: "info",
              permission: ["OEM"]
            }
          },
          // {
          //   path: '/oem/open',
          //   name: 'oem-open',
          //   component: () => import('@/views/oem/open'),
          //   meta: {
          //     hiddenHeaderContent: false,
          //     title: '分站订单',
          //     icon: 'file-text',
          //     permission: ['OEM']
          //   }
          // },
          {
            path: "/oem/order",
            name: "oem-order",
            component: () => import("@/views/order/oem"),
            meta: {
              hiddenHeaderContent: false,
              title: "订单统计",
              icon: "file-text",
              permission: ["OEM"]
            }
          },
          {
            path: "/order/oem/detail",
            name: "orderOemDetail",
            component: () => import("@/views/orderDetail/oem"),
            meta: {
              hiddenHeaderContent: false,
              title: "发货列表",
              icon: "shopping-cart",
              permission: ["NORMAL"]
            }
          },
          {
            path: "/oem/user",
            name: "oemUser",
            component: () => import("@/views/oem/user"),
            meta: {
              hiddenHeaderContent: false,
              title: "用户管理",
              icon: "user",
              permission: ["OEM"]
            }
          }
        ]
      },
      {
        path: "/app",
        name: "app",
        redirect: "",
        component: RouteView,
        meta: { title: "接口对接", icon: "tag", permission: ["OEM", "NORMAL"] },
        children: [
          {
            path: "/app/base",
            name: "appBase",
            component: () => import("@/views/oem/base"),
            meta: {
              title: "API信息",
              hidden: true,
              icon: "info",
              permission: ["OEM", "NORMAL"]
            }
          }
        ]
      }
    ]
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/",
    component: UserLayout,
    redirect: "/index",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/login")
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/register")
      },
      {
        path: "rePwd",
        name: "rePwd",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/rePwd")
      }
    ]
  },
  {
    path: "/404",
    component: () => import(/* webpackChunkName: "fail" */ "@/views/404")
  },
  {
    path: "/index",
    name: "index",
    component: () => import(/* webpackChunkName: "fail" */ "@/views/index")
  },

  {
    path: "/taobao",
    name: "taobao",
    component: () =>
      import(/* webpackChunkName: "taobao" */ "@/views/user/taobao")
  },
  {
    path: "/fwtaobao",
    name: "fwtaobao",
    component: () =>
      import(/* webpackChunkName: "taobao" */ "@/views/user/fwtaobao")
  },
  {
    path: "/alibaba",
    name: "alibaba",
    component: () =>
      import(/* webpackChunkName: "taobao" */ "@/views/user/alibaba")
  },
  {
    path: "/douyin",
    name: "douyin",
    component: () =>
      import(/* webpackChunkName: "douyin" */ "@/views/user/douyin")
  },
  {
    path: "/pdd",
    name: "pdd",
    component: () => import(/* webpackChunkName: "pdd" */ "@/views/user/pdd")
  },
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/join")
  },
  {
    path: "/systemNotice",
    name: "systemNotice",
    component: () => import("@/views/systemNotice")
  },
  {
    path: "/clientLogin",
    name: "clientLogin",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/client/login")
  }
];
