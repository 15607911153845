import { axios } from "@/utils/request";
import { replaceImage } from "@/utils/util";

// sip
export function sipLogin(data) {
  return axios({ url: "sip/login/account", method: "post", data: data });
}
export function sipLogout() {
  return axios({ url: "/sip/logout", method: "post" });
}
// sip user
export function sipUser() {
  return axios({ url: "/sip/user", method: "get" });
}
export function sipUserByUid(uid) {
  return axios({ url: "/sip/user/" + uid, method: "get" });
}
export function sipUserList(params) {
  return axios({ url: "/sip/user/list", method: "get", params: params });
}
export function sipUpdate(data) {
  return axios({ url: "/sip/update", method: "post", data: data });
}
export function sipUserUpdate(data) {
  return axios({ url: "sip/user/update", method: "post", data: data });
}

export function addressAnalysis(add) {
  return axios({ url: "/address/analysis", method: "post", data: { add } });
}
export function sipRoleUpdateUser(data) {
  return axios({ url: "/sip/role/update/user", method: "post", data: data });
}
export function sipUpdatePassword(data) {
  return axios({ url: "/sip/update/password", method: "post", data: data });
}
export function sipUpdateMobile(data) {
  return axios({ url: "/sip/update/mobile", method: "post", data: data });
}
// 1 手机号需要不存在  2  手机号需要存在
export function sipSms(mobile, type) {
  return axios({
    url: "/sip/sms",
    method: "post",
    data: { mobile: mobile, type: type }
  });
}
export function sipRegister(data) {
  return axios({ url: "/sip/register", method: "post", data: data });
}
export function sipFindPassword(data) {
  return axios({ url: "/sip/find/password", method: "post", data: data });
}

// account
export function accountGet() {
  return axios({ url: "/account/get", method: "get" });
}

export function accountUpdate(data) {
  return axios({ url: "/account/update", method: "post", data: data });
}
export function accountRefundBalance(data) {
  return axios({ url: "/account/refund/balance", method: "post", data: data });
}
export function accountCount() {
  return axios({ url: "/account/count", method: "get" });
}
export function accountList(type, params) {
  return axios({ url: "/account/list/" + type, method: "get", params: params });
}
export function accountInviterCheck(c) {
  return axios({ url: "/account/inviter/check/" + c, method: "get" });
}
// account  withdraw
export function accountWithdraw(data) {
  return axios({ url: "/account/withdraw", method: "post", data: data });
}
export function accountWithdrawPass(data) {
  return axios({ url: "/account/withdraw/pass", method: "post", data: data });
}
export function accountWithdrawList(params) {
  return axios({
    url: "/account/withdraw/list",
    method: "get",
    params: params
  });
}

// recharge
export function rechargePay(data) {
  return axios({ url: "/recharge/pay", method: "post", data: data });
}
export function rechargeList(params) {
  return axios({ url: "/recharge/list", method: "get", params: params });
}

// bill
export function billList(params) {
  return axios({ url: "/bill/list", method: "get", params: params });
}
export function billAdminList(params) {
  return axios({ url: "/bill/list/admin", method: "get", params: params });
}

// oem
export function oemGet(params) {
  return axios({ url: "/oem", method: "get", params: params });
}
export function oemList(params) {
  return axios({ url: "/oem/list", method: "get", params: params });
}
export function oemInsert(data) {
  return axios({ url: "/oem/insert", method: "post", data: data });
}
export function oemUpdate(data) {
  return axios({ url: "/oem/update", method: "post", data: data });
}
export function oemDelete(id) {
  return axios({ url: "/oem/delete", method: "delete", data: [id] });
}
export function oemInviterList(params) {
  return axios({ url: "/oem/list/inviter", method: "get", params: params });
}

export function oemInviterUpdate(data) {
  return axios({ url: "/oem/update/inviter", method: "post", data: data });
}
// app
export function appGet() {
  return axios({ url: "/app/get", method: "get" });
}
export function appReset() {
  return axios({ url: "/app/reset", method: "get" });
}
export function appUpdate(data) {
  return axios({ url: "/app/update", method: "post", data: data });
}
export function appCallback() {
  return axios({ url: "/app/callback", method: "post" });
}

// address
export function addressGet() {
  return axios({ url: "/address", method: "get" });
}
export function addressList(platform) {
  return axios({
    url: "/address/list",
    method: "get",
    params: { platform: platform }
  });
}
export function addressListA() {
  return axios({
    url: "/address/list",
    method: "get",
    params: { showAll: true, enabled: true }
  });
}

export function addressLogisticsAll() {
  return axios({
    url: "/address/logistics/all",
    method: "get",
    params: {}
  });
}

export function addressUpdate(data) {
  return axios({ url: "/address/update", method: "post", data: data });
}
// /address/logistics
export function addressLogisticsList(params) {
  return axios({
    url: "/address/logistics/list",
    method: "get",
    params: params
  });
}
export function addressLogisticsListByUser(uid) {
  return axios({ url: "/address/logistics/list/" + uid, method: "get" });
}
export function addressLogisticsUpdate(data) {
  return axios({
    url: "/address/logistics/update",
    method: "post",
    data: data
  });
}
export function addressLogisticsInsert(data) {
  return axios({
    url: "/address/logistics/insert",
    method: "post",
    data: data
  });
}
export function addressByUser(uid) {
  return axios({ url: "/address/" + uid, method: "get" });
}

// /goods
export async function goodsList(params) {
  const res = await axios({
    url: "/goods/list",
    method: "get",
    params: params
  });
  if (res.code === 0) {
    res.data.list.forEach(val => {
      val.picture = replaceImage(val.picture);
    });
  }
  return res;
}
export async function goodsListAll(params) {
  const res = await axios({
    url: "/goods/list/all",
    method: "get",
    params: params
  });
  if (res.code === 0) {
    res.data.list.forEach(val => {
      val.picture = replaceImage(val.picture);
    });
  }
  return res;
}
export function goodsUpdate(data) {
  return axios({ url: "/goods/update", method: "post", data: data });
}
export function goodsInsert(data) {
  return axios({ url: "/goods/insert", method: "post", data: data });
}

// /goods/partition
export function goodsPartitionList(params) {
  return axios({ url: "/goods/partition/list", method: "get", params: params });
}
export function goodsPartitionUpdate(data) {
  return axios({ url: "/goods/partition/update", method: "post", data: data });
}
export function goodsPartitionInsert(data) {
  return axios({ url: "/goods/partition/insert", method: "post", data: data });
}

// order
export function orderStatistics(params) {
  return axios({
    url: "/order/detail/select/sum",
    method: "get",
    params: params
  });
}
export function orderGet(type, id) {
  return axios({ url: "/order/" + type + "/" + id, method: "get" });
}
export async function orderList(params, type) {
  const res = await axios({
    url: "/order/list/" + type,
    method: "get",
    params: params
  });
  if (res.code === 0) {
    res.data.list.forEach(val => {
      if (val.goods) {
        val.goods.picture = replaceImage(val.goods.picture);
      }
    });
  }
  return res;
}
export function orderCount(params, type) {
  return axios({ url: "/order/count/" + type, method: "get", params: params });
}
export function orderUpdate(data, type) {
  return axios({ url: "/order/update/" + type, method: "post", data: data });
}
export function orderInsert(data) {
  return axios({ url: "/order/insert", method: "post", data: data });
}
export function orderPay(data) {
  return axios({ url: "/order/pay", method: "post", data: data });
}
export function orderCancel(id) {
  return axios({ url: "/order/cancel/" + id, method: "delete" });
}
// 发此订单
export function orderSendOne(id) {
  return axios({ url: "/order/send/" + id, method: "post" });
}
export function orderClose(type, id) {
  return axios({ url: "/order/close/" + type + "/" + id, method: "delete" });
}
export function orderPlatformToId(id) {
  return axios({ url: "/order/platform/to/" + id, method: "post" });
}
// order/detail
export function orderDetailClose(type, id) {
  return axios({
    url: "/order/detail/close/" + type + "/" + id,
    method: "delete"
  });
}
export function orderDetailSend(data) {
  return axios({ url: "/order/detail/send", method: "post", data: data });
}
export function orderDetailUpdate(data, type) {
  return axios({
    url: "/order/detail/update/" + type,
    method: "post",
    data: data
  });
}
export function orderDetailList(params, type) {
  return axios({
    url: "/order/detail/list/" + type,
    method: "get",
    params: params
  });
}
export function orderDetailSendMany(data) {
  return axios({
    url: "/order/detail/send/many",
    method: "post",
    data: data,
    timeout: 100000
  });
}

// file
export function filePreUpload() {
  return axios({ url: "/file/pre/upload", method: "get" });
}
// 上传到OSS
export function uploadOSS(url, formData) {
  return axios({
    url: url,
    method: "post",
    processData: false,
    data: formData
  });
}

// store
// 最新
// 快递列表
export const waybill = platform => {
  return axios({
    method: "GET",
    url: "/address/list/waybill",
    params: { platform }
  });
};
// 下单
export const addOrder = data => {
  return axios({
    method: "POST",
    url: "/order/insert/waybill",
    data
  });
};
// 查询余额
export const queryMoney = params => {
  return axios({
    url: "/account/get",
    params
  });
};
// 付款
export const Payment = data => {
  return axios({
    method: "POST",
    url: "/order/pay",
    data
  });
};
export function storeList(params) {
  if (params) {
    params.pageSize = 2000;
  } else {
    params = { pageSize: 2000 };
  }
  return axios({ url: "/shop/list", method: "get", params: params });
}
export function storeOrders(id, data) {
  return axios({
    url: "/shop/orders/" + id,
    method: "post",
    data: data,
    timeout: 1000 * 600 * 10 * 10 * 10
  });
}
// export function storeInsert (data, token) { return axios({ url: '/shop/insert', method: 'post', headers: {'Authorization': token}, data: data }) }
export function storeDelete(id) {
  return axios({ url: "/shop/delete/" + id, method: "delete" });
}
export function storeUpdate(type, data) {
  return axios({ url: "/shop/update/" + type, method: "post", data: data });
}

/** 店铺代码绑定 */
export function storeCodeBind(data) {
  return axios({ url: "/shop/insert", method: "post", data });
}

// premium
export function premiumList(params) {
  return axios({ url: "/premium/list", method: "get", params: params });
}
export function premiumUpdate(data) {
  return axios({ url: "/premium/update", method: "post", data: data });
}
export function premiumInsert(data) {
  return axios({ url: "/premium/insert", method: "post", data: data });
}
export function premiumDelete(id) {
  return axios({ url: "/premium/delete/" + id, method: "delete" });
}
// agent/level
export function agentLevelAll(params) {
  return axios({ url: "/agent/level/all", method: "get", params: params });
}

// agent/order
export function agentOrderGet(params) {
  return axios({ url: "/agent/order/get", method: "get", params: params });
}
export function agentOrderPay(data) {
  return axios({ url: "/agent/order/pay", method: "post", data: data });
}
export function agentAlipayCheck(data) {
  return axios({ url: "/alipay/check", method: "post", data: data });
}
// 淘宝文件解密
export function getExcelPassword(data) {
  return axios({ url: "/file/excel/password", method: "post", data: data });
}

// 首页推广
export function extensionListGetAdmin(params) {
  return axios({ url: "/extension/list/admin", method: "get", params: params });
}
export function extensionListGetUser(params) {
  return axios({ url: "/extension/list/user", method: "get", params: params });
}
export function extensionInsert(data) {
  return axios({ url: "/extension/insert", method: "post", data: data });
}
export function extensionUpdate(data) {
  return axios({ url: "/extension/update", method: "post", data: data });
}
export function extensionDelete(id) {
  return axios({ url: "/extension/delete", method: "delete", data: [id] });
}

// 代发端下载
// type 1 普通用户  查询自己所有的子订单
//   2 返回自己出售的所有子订单
export function orderAllDetail(type, params) {
  return axios({
    url: "/order/detail/all/" + type,
    method: "get",
    params: params
  });
}
// 日志

export function logAdminListGet(params) {
  return axios({ url: "log/list/admin", method: "get", params: params });
}
export function logAppListGet(params) {
  return axios({ url: "log/list/app", method: "get", params: params });
}
// 分站订单

export function agentOrderList(type, params) {
  return axios({
    url: "/agent/order/list/" + type,
    method: "get",
    params: params
  });
}

export function accountListByCustomer(params) {
  return axios({
    url: "/account/list/customer",
    method: "get",
    params: params
  });
}
export function accountGroupByCustomer(id) {
  return axios({
    url: "/account/group/customer/" + id,
    method: "get"
  });
}
export function accountBindCustomer(data) {
  return axios({
    url: "/account/bind/customer",
    method: "post",
    data: data
  });
}
export function accountCustomerUpdate(data) {
  return axios({
    url: "/account/customer",
    method: "post",
    data: data
  });
}
// 插件订单
export function extOrderList(type, params) {
  return axios({
    url: "/order/detail/all/" + type,
    method: "get",
    params: params
  });
}
// 插件发货
export function extOrderSend(data) {
  return axios({
    url: "/order/detail/ext/send",
    method: "post",
    data: data
  });
}

// TODO 客户端相关
// 打开记录
export function clientNowInsert(data) {
  return axios({ url: "/client/now/insert", method: "post", data: data });
}
// 登录记录
export function clientInsert(data) {
  return axios({ url: "/client/insert", method: "post", data: data });
}

// 获取本地ip（在客户端是可以跨域的）
export async function ipGet() {
  return axios({ url: "//2021.ip138.com/", method: "get" });
}

// x5
/**
 * 通过cookie\orderId获取地址
 * @param cookie
 * @param orderId
 * @returns {AxiosPromise}
 */
export function x5CookieAddress(cookie, orderId) {
  return axios({
    url: "/x5/cookie/address",
    method: "post",
    data: { cookie, orderId }
  });
}
/**
 * 通过cookie 获取header头
 * @param cookie
 * @returns {AxiosPromise}
 */
export function x5CookieHeader(cookie) {
  return axios({
    url: "/x5/cookie/header",
    method: "post",
    data: { cookie },
    timeout: 1000 * 600 * 10 * 10 * 10
  });
}
// 请求淘宝接口（在客户端是可以跨域的）
export function x5OrderList(url, formData, headers) {
  return axios({
    url: url,
    method: "post",
    processData: false,
    data: formData,
    headers
  });
}

/** 仓库管理页接口 */
// 删除快递公司
export const removeCompanyApi = async id => {
  return axios({ url: `/address/logistics/delete/${id}`, method: "delete" });
};

// 删除仓库
export const removeStoreApi = async id => {
  return axios({ url: `/address/delete/${id}`, method: "delete" });
};

/** 获取仓库列表 */
export const queryStoreList = async params => {
  return axios({
    method: "get",
    url: "/address/list/admin",
    params
  });
};

/** 新增仓库 根据UID获取 */
export const queryAndInsertStoreInfo = async uid => {
  return axios({
    method: "post",
    url: "/address/insert",
    data: { uid }
  });
};

/** 所有关闭类型 */
export const queryCloseList = async () => {
  return axios({
    method: "get",
    url: "/close/type/all"
  });
};
/** 删除关闭类型 */
export const removeCloseType = async id => {
  return axios({
    method: "post",
    url: "/close/type/delete",
    data: { id }
  });
};

/**
 * 新增关闭
 * @param data.keyWord 错误信息 String
 * @param data.time 时间 分钟 Number
 * @param data.operate 关闭("CLOSE"), 重推("REUSE"),
 */
export const insertCloseType = async data => {
  return axios({
    method: "post",
    url: "/close/type/insert",
    data
  });
};

/** 根据单号查询投诉列表
 * @param waybillNoList 数组 []
 */
export const queryComplaintList = async waybillNoList => {
  return axios({
    method: "post",
    url: "/order/detail/complaint/list",
    data: { waybillNoList }
  });
};
