<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a-dropdown style="marginLeft:24px">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <img
            src="../../assets/icon／在线客服2.png"
            alt
            style="width:20px,height:20px"
          />
          <span style="marginLeft:4px">联系客服</span>
        </a>
        <a-menu
          slot="overlay"
          style="top: 20px;min-width: 101px;text-align:center"
        >
          <a-menu-item key="0" @click="openConcat">
            <span>在线客服</span>
          </a-menu-item>

          <a-menu-item
            style="position: relative"
            key="1"
            v-if="baseData.wechat"
            @mouseenter="
              () => {
                wechatImgShow = true;
              }
            "
            @mouseleave="
              () => {
                wechatImgShow = false;
              }
            "
          >
            <span>微信</span>
            <div v-show="wechatImgShow" class="wechat_img">
              <img
                border="0"
                :src="baseData.wechat"
                alt="扫码加好友"
                title="扫码加好友"
                style="width:100px;height:100px;display:block;"
              />
            </div>
          </a-menu-item>
          <a-menu-item key="2" @click="openqq" v-if="baseData.qq">
            <span>QQ</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-dropdown style="marginLeft:24px">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <a-icon type="user" />
          <span style="marginLeft:4px">{{ user.mobile }}</span>
        </a>
        <a-menu
          slot="overlay"
          style="top: 20px;min-width: 101px;text-align:center"
        >
          <a-menu-item
            key="0"
            @click="
              () => {
                $router.push('/account/base');
              }
            "
          >
            <span>个人中心</span>
          </a-menu-item>
          <a-menu-item key="1" @click="handleLogout">
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserMenu',
  components: {},
  data() {
    return {
      wechatImgShow: false,
      baseData: baseData,
      user: this.userInfo()
    };
  },

  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['userInfo']),
    handleLogout() {
      const that = this;
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk() {
          return that
            .Logout({})
            .then(() => {
              // window.location.reload();
              // 客户端退出到clientLogin
              if (typeof window.client !== "undefined") {
                return (window.location.href = "/clientLogin");
              }
              // 后台退出登录跳转回首页
              window.location.href = '/index';
            })
            .catch((err) => {
              that.$message.error({
                title: '错误',
                description: err.message
              });
            });
        },
        onCancel() {}
      });
    },
    openConcat() {
      window.open(
        'https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817c5390744c9abd4ae0e0344549e2a29e6fdc2fdf2b2137a1eaebae5c0f1ad81b0ab6a09db04a4b9520996e25ff7c'
      );
    },
    openqq() {
      window.open(
        `http://wpa.qq.com/msgrd?v=3&uin=${baseData.qq}&site=qq&menu=yes`
      );
    }
  }
};
</script>
<style lang="less" scoped>
.user-wrapper {
  a {
    color: #ffffff;
    opacity: 0.7;
  }
}
.wechat_img {
  width: 120px;
  height: 120px;
  background-image: url('../../assets/联合 12.png');
  background-repeat: no-repeat;
  background-size: 120px 120px;
  position: absolute;
  top: -20px;
  left: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
}
</style>
