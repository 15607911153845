<template>
  <transition name="showHeader">
    <div v-if="visible" class="header-animat">
      <a-layout-header
        v-if="visible"
        :class="[
          fixedHeader && 'ant-header-fixedHeader',
          sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed'
        ]"
        :style="{ padding: '0' }"
      >
        <div v-if="mode === 'sidemenu'" class="header">
          <Logo />
          <a-icon
            v-if="device === 'mobile'"
            class="trigger"
            :type="collapsed ? 'menu-fold' : 'menu-unfold'"
            @click="toggle"
            style="color: #fff"
          />
          <!--
          <a-icon
            v-else
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="toggle"
          />-->

          <div class="top_menu">
            <div
              class="top_menu_item"
              :class="headerIndex == 0 ? 'active' : ''"
              @click="changeHeaderIndex(0)"
            >
              首页
            </div>
            <div
              class="top_menu_item"
              :class="headerIndex == 1 ? 'active' : ''"
              @click="changeHeaderIndex(1)"
            >
              礼品商城
            </div>
            <!--            <div-->
            <!--              class="top_menu_item"-->
            <!--              :class="headerIndex == 2 ? 'active' : ''"-->
            <!--              @click="changeHeaderIndex(2)"-->
            <!--            >-->
            <!--              分站开通-->
            <!--            </div>-->
            <div class="top_menu_item" @click="openHelp">
              操作手册
            </div>

            <!--            <div class="top_menu_item" @click="openylh">-->
            <!--              店铺流量提升-->
            <!--            </div>-->
            <div
              v-if="noticeList.length"
              class="carousel_item"
              @click="$router.push({ path: '/systemNotice' })"
            >
              <a-icon type="sound" style="margin-right:6px" />
              <div style="flex: 1">
                <a-carousel dot-position="right" autoplay :dots="false">
                  <div class="fontC" v-for="item in noticeList" :key="item.id">
                    {{ item.title }} {{ formatDate(item.createTime) }}
                  </div>
                </a-carousel>
              </div>
            </div>
          </div>

          <user-menu></user-menu>
        </div>
        <div v-else :class="['top-nav-header-index', theme]">
          <div class="header-index-wide">
            <div class="header-index-left">
              <logo class="top-nav-header" :show-title="device !== 'mobile'" />
              <s-menu
                v-if="device !== 'mobile'"
                mode="horizontal"
                :menu="menus"
                :theme="theme"
              />
              <a-icon
                v-else
                class="trigger"
                :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                @click="toggle"
              />
            </div>
            <user-menu class="header-index-right"></user-menu>
          </div>
        </div>
      </a-layout-header>
    </div>
  </transition>
</template>

<script>
import UserMenu from "../tools/UserMenu";
import SMenu from "../Menu/";
import { mixin } from "@/utils/mixin";
import Logo from "@/components/tools/Logo";
import { noticeListGet } from "@/self";
import { formatDate } from "@/utils/util";
export default {
  name: "GlobalHeader",
  components: {
    UserMenu,
    SMenu,
    Logo
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: "sidemenu"
    },
    menus: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: "dark"
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    device: {
      type: String,
      required: false,
      default: "desktop"
    }
  },
  data() {
    return {
      headerIndex: 1,
      visible: true,
      oldScrollTop: 0,
      baseData: baseData,
      noticeList: []
    };
  },
  mounted() {
    document.body.addEventListener("scroll", this.handleScroll, {
      passive: true
    });
    if (this.$route.name != "purchase") {
      this.headerIndex = 5;
    }
    this.getNotice();
  },
  watch: {
    $route: {
      handler: function(val) {
        if (val.name == "purchase") {
          this.headerIndex = 1;
        } else {
          this.headerIndex = 5;
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    formatDate,
    getNotice() {
      const params = { pageNum: 1, pageSize: 100 };
      noticeListGet(params).then(res => {
        console.log("[res] ---> ", res);
        // 展示前3条
        this.noticeList = res.data.list.slice(0, 3);
      });
    },
    teachVideo() {
      window.open("https://v.qq.com/x/page/e3216wwprvt.html");
    },
    handleScroll() {
      if (!this.autoHideHeader) {
        return;
      }

      const scrollTop =
        document.body.scrollTop + document.documentElement.scrollTop;
      if (!this.ticking) {
        this.ticking = true;
        requestAnimationFrame(() => {
          if (this.oldScrollTop > scrollTop) {
            this.visible = true;
          } else if (scrollTop > 300 && this.visible) {
            this.visible = false;
          } else if (scrollTop < 300 && !this.visible) {
            this.visible = true;
          }
          this.oldScrollTop = scrollTop;
          this.ticking = false;
        });
      }
    },
    toggle() {
      this.$emit("toggle");
    },
    openylh() {
      window.open("http://" + this.baseData.otherHost);
    },
    openHelp() {
      window.open(
        "https://alidocs.dingtalk.com/i/nodes/X6GRezwJlYLmjZBKFGlGwRMy8dqbropQ?utm_scene=team_space"
      );
    },
    changeHeaderIndex(index) {
      this.headerIndex = index;
      if (index == 0) {
        setTimeout(() => {
          this.$router.push({ path: "/index" });
        }, 200);
      }
      if (index == 1) {
        setTimeout(() => {
          this.$router.push({ path: "/goods/purchase" });
        }, 200);
      }
      if (index == 2) {
        setTimeout(() => {
          this.$router.push({ path: "/join" });
        }, 200);
      }
      if (index == 3) {
        setTimeout(() => {
          this.$router.push({ path: "/systemNotice" });
        }, 200);
      }
    }
  },
  beforeDestroy() {
    document.body.removeEventListener("scroll", this.handleScroll, true);
  }
};
</script>

<style lang="less" scoped>
.header-animat {
  position: relative;
  z-index: 999;
}
.header {
  display: flex;
  justify-content: center;
}
.layout.ant-layout .header {
  background-image: url("../../assets/bg／顶部导航栏 .png");
  background-color: #001a66; //背景图未加载前的过渡背景色
}
.showHeader-enter-active {
  transition: all 0.25s ease;
}
.showHeader-leave-active {
  transition: all 0.5s ease;
}
.showHeader-enter,
.showHeader-leave-to {
  opacity: 0;
}

.top_menu {
  flex: 1;
  display: flex;
  justify-content: center;
  .top_menu_item {
    padding: 24px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    opacity: 0.7;
    &:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.15);
    }
    &.active {
      opacity: 1;
      background: rgba(0, 0, 0, 0.15);
    }
  }
}
.carousel_item {
  margin-left: 24px;
  cursor: pointer;
  width: 280px;
  display: flex;
  align-items: center;
  color: #fff;
  .fontC {
    color: #fff;
    overflow: hidden; //超出文本隐藏
    white-space: nowrap; //溢出不换行
    text-overflow: ellipsis; //溢出省略号显示
  }
}
.circle_fat {
  position: relative;
}
.circle {
  opacity: 1;
  position: absolute;
  top: 18px;
  right: 18px;
  background-color: #f93244;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
/deep/.slick-list {
  height: 23px !important;
}
</style>
