var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{class:[
    _vm.isDesktop() ? null : 'shadow',
    _vm.theme,
    _vm.fixSiderbar ? 'ant-fixed-sidemenu' : null ],style:({
    overflow: 'auto',
    height: 'calc(100vh - 64px)',
    padding: '0px',
    backgroundColor: '#fff',
  }),attrs:{"width":"200px","collapsible":_vm.collapsible,"trigger":null},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[(!_vm.collapsed)?_c('div',{staticClass:"buynow_btn",on:{"click":_vm.noGoodsFn}},[_vm._v(" 礼品采购 ")]):_vm._e(),_c('s-menu',{attrs:{"collapsed":_vm.collapsed,"menu":_vm.menus,"theme":_vm.theme,"mode":_vm.mode},on:{"select":_vm.onSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }