<template>
  <a-layout :class="['layout', device]">
    <!-- 上下布局 -->
    <a-layout
      :class="[layoutMode, `content-width-${contentWidth}`]"
      :style="{ paddingLeft: contentPaddingLeft, minHeight: '100vh' }"
    >
      <global-header
        :mode="layoutMode"
        :menus="menus"
        :theme="navTheme"
        :collapsed="collapsed"
        :device="device"
        @toggle="toggle"
      />

      <a-layout>
        <!-- SideMenu -->
        <a-drawer
          width="200"
          v-if="isMobile()"
          placement="left"
          :wrapClassName="`drawer-sider light`"
          :closable="false"
          :visible="collapsed"
          @close="drawerClose"
        >
          <side-menu
            mode="inline"
            :menus="menus"
            :theme="navTheme"
            :collapsed="false"
            :collapsible="true"
            @menuSelect="menuSelect"
          ></side-menu>
        </a-drawer>

        <side-menu
          v-else-if="isSideMenu()"
          mode="inline"
          :menus="menus"
          :theme="navTheme"
          :collapsed="collapsed"
          :collapsible="true"
        ></side-menu>
        <a-layout :style="{height:'calc(100vh - 64px)'}">
          <!-- layout content -->
          <a-layout-content :style="{ height: '100%', paddingTop: fixedHeader ? '64px' : '0' }">
            <!-- <multi-tab v-if="multiTab"></multi-tab> -->
            <transition name="page-transition">
              <route-view />
            </transition>
            <!-- layout footer -->
            <a-layout-footer>
              <global-footer />
            </a-layout-footer>
          </a-layout-content>
        </a-layout>
      </a-layout>

      <!-- Setting Drawer (show in development mode)  右侧的皮肤栏，不根据当前环境，均显示 -->
      <!-- <setting-drawer></setting-drawer> -->
    </a-layout>
  </a-layout>
</template>

<script>
import { triggerWindowResizeEvent } from "@/utils/util";
import { mapState, mapActions } from "vuex";
import { mixin, mixinDevice } from "@/utils/mixin";
import config from "@/config/defaultSettings";

import RouteView from "./RouteView";
import MultiTab from "@/components/MultiTab";
import SideMenu from "@/components/Menu/SideMenu";
import GlobalHeader from "@/components/GlobalHeader";
import GlobalFooter from "@/components/GlobalFooter";
import SettingDrawer from "@/components/SettingDrawer";

export default {
  name: "BasicLayout",
  mixins: [mixin, mixinDevice],
  components: {
    RouteView,
    MultiTab,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer,
  },
  data() {
    return {
      production: config.production,
      collapsed: false,
      menus: [],
    };
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
    contentPaddingLeft() {
      if (!this.fixSidebar || this.isMobile()) {
        return "0";
      }
      if (this.sidebarOpened) {
        return "256px";
      }
      return "80px";
    },
  },
  watch: {
    sidebarOpened(val) {
      this.collapsed = !val;
    },
  },
  created() {
    this.menus = this.mainMenu.find((item) => item.path === "/").children;
    this.collapsed = !this.sidebarOpened;
  },
  mounted() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Edge") > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed;
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 16);
      });
    }
  },
  methods: {
    ...mapActions(["setSidebar"]),
    toggle() {
      this.collapsed = !this.collapsed;
      this.setSidebar(!this.collapsed);
      triggerWindowResizeEvent();
    },
    paddingCalc() {
      let left = "";
      if (this.sidebarOpened) {
        left = this.isDesktop() ? "256px" : "80px";
      } else {
        left = (this.isMobile() && "0") || (this.fixSidebar && "80px") || "0";
      }
      return left;
    },
    menuSelect() {
      if (!this.isDesktop()) {
        this.collapsed = false;
      }
    },
    drawerClose() {
      this.collapsed = false;
    },
  },
};
</script>

<style lang="less">
@import url("../components/global.less");

/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */

.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
