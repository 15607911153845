<template>
  <a-layout-sider
    :class="[
      isDesktop() ? null : 'shadow',
      theme,
      fixSiderbar ? 'ant-fixed-sidemenu' : null,
    ]"
    width="200px"
    :collapsible="collapsible"
    v-model="collapsed"
    :trigger="null"
    :style="{
      overflow: 'auto',
      height: 'calc(100vh - 64px)',
      padding: '0px',
      backgroundColor: '#fff',
    }"
  >
    <div v-if="!collapsed" class="buynow_btn" @click="noGoodsFn">
      礼品采购
    </div>
    <s-menu
      :collapsed="collapsed"
      :menu="menus"
      :theme="theme"
      :mode="mode"
      @select="onSelect"
    ></s-menu>
  </a-layout-sider>
</template>

<script>
import SMenu from "./index";
import { mixin, mixinDevice } from "@/utils/mixin";
import eventBus from '@/utils/eventBus'

export default {
  name: "SideMenu",
  components: { SMenu },
  mixins: [mixin, mixinDevice],
  props: {
    mode: {
      type: String,
      required: false,
      default: "inline",
    },
    theme: {
      type: String,
      required: false,
      default: "light",
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false,
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    menus: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    noGoodsFn () {
      // console.log(1)
      // eventBus.$emit('changePageGoods')
      this.$router.push({ name: 'purchase' })
    }
  }
}
</script>
<style lang="less" scoped>
.buynow_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 32px;
  width: 136px;
  height: 32px;
  background: linear-gradient(
    141deg,
    rgba(13, 110, 255, 1) 0%,
    rgba(48, 165, 255, 0.53) 100%
  );
  color: #fff;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #eee;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}
</style>
