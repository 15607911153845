// ie polyfill
import '@babel/polyfill';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/';
import { VueAxios } from './utils/request';

import bootstrap from './core/bootstrap';
import './core/use';
import './permission'; // permission control
import './utils/filter'; // global filter
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
Vue.use(preview);
Vue.config.productionTip = false;

// mount axios Vue.$http and this.$http
Vue.use(VueAxios);
function copyInfo(text) {
  const oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand('Copy');
  oInput.style.display = 'none';
  document.body.removeChild(oInput);
  this.$message.success('复制成功');
}
Vue.prototype.copyInfo = copyInfo;
import Meta from 'vue-meta';
Vue.use(Meta);

console.info('window.client', window.client);

new Vue({
  router,
  metaInfo() {
    return {
      title: '首页' // 在这里直接用了同一个title，如果每个页面的title不一样，参考上述链接中的做法
    };
  },
  store,
  created() {
    bootstrap();
  },
  render: (h) => h(App)
}).$mount('#app');
